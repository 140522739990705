import React, { useState } from 'react';
import {
  TextField, Button, MenuItem, CircularProgress,
} from '@mui/material';
import { Add, Done, WarningAmber } from '@mui/icons-material';

import { toast } from 'react-toastify';

import styled from 'styled-components';
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { request } from '../../functions/request';
import PageWrapper from '../../components/global/PageWrapper';
import NewParcelTile from '../../components/customer/NewParcelTile';
import useNewDelivery from '../../hooks/customer/useNewDelivery';

export default function NewDelivery() {
  const {
    selectedAddress,
    setSelectedAddress,
    selectedDate,
    setSelectedDate,
    parcel,
    setParcel,
    quantity,
    setQuantity,
    expectedParcels,
    setExpectedParcels,
    addressData,
    totalQuantity,
    notes,
    setNotes,
    addressesLoading,
  } = useNewDelivery();

  let validationFail = false;

  // validation states
  const [nameOnParcelError, setNameOnParcelError] = useState(false);
  const [dateExpectedError, setDateExpectedError] = useState(false);
  const [selectedAddressError, setSelectedAddressError] = useState(false);

  const handleChange = (event) => {
    setParcel({ ...parcel, [event.target.name]: event.target.value });
  };

  const submitExpectedParcels = async () => {
    if (expectedParcels.length < 1) {
      toast.error("You haven't added any items to the delivery");
      return;
    }
    request('/customer/delivery', {
      method: 'POST',
      body: {
        notes,
        addressId: selectedAddress.id,
        expectedParcels,
      },
    })
      .then(() => {
        toast.info('Delivery saved!');
        window.location.href = '/';
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const addExpectedParcel = () => {
    validationFail = false;
    setNameOnParcelError(false);
    setDateExpectedError(false);
    setSelectedAddressError(false);

    if (parcel?.nameOnParcel == null || parcel?.nameOnParcel === '') {
      validationFail = true;
      setNameOnParcelError(true);
    }
    if (!selectedDate._isValid) {
      validationFail = true;
      setDateExpectedError(true);
    }
    if (selectedAddress == null) {
      validationFail = true;
      setSelectedAddressError(true);
    }

    if (validationFail) {
      toast.error('Please fill in all the fields');
      return;
    }

    setExpectedParcels([
      ...expectedParcels,
      {
        ...parcel,
        dateExpected: selectedDate._d,
        quantity,
        id: new Date().getTime(),
      },
    ]);

    setParcel();
    setQuantity(1);
  };

  return (
    <PageWrapper
      pageTitle="New Delivery"
      children={(
        <PageWidgets>
          <NewDeliveryWidget>
            <h2>Address Details</h2>
            <NewParcelInputList>
              <TextField
                label="Address*"
                name="addressId"
                error={selectedAddressError}
                select
                fullWidth
                value={selectedAddress ?? ''}
                onChange={(e) => setSelectedAddress(e.target.value)}
                variant="outlined"
                size="small"
              >
                {addressesLoading ? (
                  <MenuItem>
                    <Center>
                      <CircularProgress size="30px" />
                    </Center>
                  </MenuItem>
                ) : null}
                {!addressesLoading &&
                  addressData?.map((address) => (
                    <MenuItem key={address.id} value={address}>
                      <MiddleAlign>
                        {address.status !== 'AVAILABLE' && (
                          <WarningAmber
                            sx={{
                              marginRight: '10px',
                            }}
                          />
                        )}

                        {`${address.country} (#${address.reshipperName})`}
                      </MiddleAlign>
                    </MenuItem>
                  ))}
              </TextField>
              {selectedAddress?.status && selectedAddress?.status !== 'AVAILABLE' && (
                <AddressStatusTile>
                  <AddressStatusTileIcon>
                    <WarningAmber fontSize="large" />
                  </AddressStatusTileIcon>
                  <div>
                    <h2>Address Information</h2>
                    <p>
                      This reshipper may not be available to receive this order. Please check the
                      address status on the Addresses page before proceeding.
                    </p>
                  </div>
                </AddressStatusTile>
              )}
              <TextField
                label="Notes"
                name="notes"
                value={notes ?? ''}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                size="small"
              />
            </NewParcelInputList>

            <h2>Add Expected Parcels</h2>
            <NewParcelInputList>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Date Expected*"
                  name="dateExpected"
                  minDate={moment(new Date())}
                  value={moment(selectedDate)}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: dateExpectedError,
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                label="Name on Parcel*"
                error={nameOnParcelError}
                value={parcel?.nameOnParcel ?? ''}
                name="nameOnParcel"
                id="nameOnParcel"
                onChange={(e) => handleChange(e)}
                fullWidth
                variant="outlined"
                size="small"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                variant="outlined"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 1,
                  max: 250,
                }}
                type="number"
                size="small"
              />
              <NewParcelButton>
                <Button variant="contained" onClick={() => addExpectedParcel()}>
                  <Add />
                  Add Parcel
                  {quantity > 1 && 's'}
                  {' '}
                  to Delivery
                </Button>
              </NewParcelButton>
            </NewParcelInputList>
          </NewDeliveryWidget>
          <NewDeliveryWidget>
            <h2>
              Expected Parcels in Delivery (
              {totalQuantity}
              )
            </h2>
            <NewParcelList>
              {expectedParcels?.map((expectedParcel) => (
                <NewParcelTile key={expectedParcel.id} parcel={expectedParcel} />
              ))}
            </NewParcelList>
            <NewParcelButtonContainer>
              <Button
                variant="contained"
                disabled={expectedParcels.length === 0}
                onClick={() => submitExpectedParcels()}
              >
                <Done />
                Save New Delivery
              </Button>
            </NewParcelButtonContainer>
          </NewDeliveryWidget>
        </PageWidgets>
      )}
    />
  );
}

const AddressStatusTileIcon = styled.div`
  margin: auto 30px auto 10px;
  vertical-align: middle;
`;

const AddressStatusTile = styled.div`
  margin-top: 5px;
  background-color: #fbeeac;
  border-radius: 5px;
  display: flex;
  width: fit-content;
  padding: 10px;
  margin-bottom: 10px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

const PageWidgets = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const NewParcelInputList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const NewParcelButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const NewParcelButton = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const NewDeliveryWidget = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 250px;

  h2 {
    margin-bottom: 15px;
  }
`;

const NewParcelList = styled('div')`
  list-style: none;
  margin-top: 10px;
  padding: 0px;
  max-height: 550px;
  overflow-y: auto;
`;

const MiddleAlign = styled('div')`
  display: flex;
  align-items: Middle;
`;

const Center = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
